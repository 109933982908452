import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'core-client-button',
  templateUrl: './button.component.html',

  imports: [CommonModule],
})
export class ClientButtonComponent extends ButtonComponent {
  constructor() {
    super();
  }

  protected override getBgColor() {
    if (this.disabled()) return 'bg-button-disable';
    if (this.type() === 'outline' || this.type() === 'link') {
      return 'bg-primary-500';
    }
    switch (this.variant()) {
      case 'primary':
        return 'bg-special';
      case 'secondary':
        return 'bg-button-secondary';
      case 'success':
        return 'bg-button-success';
      case 'danger':
        return 'bg-button-danger';
      case 'neutral':
        return 'bg-button-neutral';
      default:
        return 'bg-button-primary';
    }
  }

  protected override getBorderColor() {
    if (this.type() === 'outline') {
      switch (this.variant()) {
        case 'primary':
          return 'border-button-primary';
        case 'secondary':
          return 'border-button-secondary';
        case 'success':
          return 'border-button-success';
        case 'danger':
          return 'border-button-danger';
        case 'neutral':
          return 'border-button-neutral';
      }
    }
    return '';
  }

  protected override getTextColor() {
    if (this.type() === 'outline' || this.type() === 'link') {
      switch (this.variant()) {
        case 'primary':
          return 'text-white';
        case 'secondary':
          return 'text-button-secondary';
        case 'success':
          return 'text-button-success';
        case 'danger':
          return 'text-button-danger';
        case 'neutral':
          return 'text-button-neutral';
        default:
          return 'text-button-primary';
      }
    }
    return 'text-white';
  }
}
